<template>
  <div
    class="fill-height"
  >
    <v-row
      no-gutters
      class="fill-height"
    >
      <v-col
        cols="9"
        class="fill-height"
      >
        <slot name="map" />
      </v-col>
      <v-col
        cols="3"
        class="fill-height"
      >
        <v-card
          tile
          class="fill-height"
        >
          <o-loader v-if="loading.crossroads" />
          <div
            v-else-if="!crossroads || !crossroads.length"
            class="fill-height"
          >
            <v-card-text class="text-center font-italic">
              {{ $t('messages.views.admin.regulation.timelines.noCrossroad') }}
            </v-card-text>
          </div>
          <div
            v-else
            class="fill-height"
          >
            <perfect-scrollbar class="absolute-scrollbar">
              <o-form
                ref="form"
                @submit="submit"
                @close="selectCrossroad(nextCrossroadId, true)"
              >
                <v-card-title
                  class="d-flex justify-space-between flex-nowrap primary--text"
                >
                  <v-btn
                    icon
                    :disabled="!isPreviousCrossroadSelectable"
                    @click="previousCrossroad"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="text-truncate">{{ selectedCrossroadName }}</span>
                  <v-btn
                    icon
                    :disabled="!isNextCrossroadSelectable"
                    @click="nextCrossroad"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-title>

                <v-divider />

                <v-card-subtitle class="font-weight-medium">
                  {{ $t('messages.views.admin.regulation.timelines.weeklyRegulation') }}
                </v-card-subtitle>
                <v-card-text>
                  <v-row
                    v-for="timelineRule in form.timelineRules"
                    :key="`regulation-rule-${timelineRule.payload.day}`"
                    no-gutters
                  >
                    <v-col
                      cols="3"
                      class="pl-4 align-center d-flex"
                    >
                      {{ $t('constants.timelineRule.type.day.payload.day.' + timelineRule.payload.day) }}
                    </v-col>
                    <v-col cols="9">
                      <o-select
                        v-model="timelineRule.timeline"
                        flat
                        solo
                        dense
                        hide-details
                        :items="form.timelines"
                        item-text="name"
                        item-value="id"
                        :placeholder="$t('messages.views.admin.regulation.timelines.noTimelineDefined')"
                        clearable
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider />

                <v-card-subtitle class="font-weight-medium">
                  <div class="d-flex justify-space-between align-center">
                    <span>{{ $t('messages.views.admin.regulation.timelines.timelines') }}</span>
                    <v-btn
                      icon
                      @click="createTimeline"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-card-subtitle>

                <v-card-text
                  v-if="timelineChanged"
                  class="py-0 my-0"
                >
                  <v-alert
                    type="info"
                    dense
                    outlined
                    class="text-center my-0"
                  >
                    Des données ont été modifiées
                  </v-alert>
                </v-card-text>

                <o-loader
                  v-if="loading.timelines"
                  height="100"
                />
                <v-card-text v-else>
                  <v-row
                    v-for="(timeline, i) in $_.sortBy(form.timelines, (timeline) => timeline.name)"
                    :key="`timeline-${i}`"
                    no-gutters
                  >
                    <v-col cols="12">
                      <div class="d-flex justify-space-between align-top">
                        <div>
                          <div class="ml-4 mb-3">
                            <div>
                              {{ timeline.name }}
                            </div>
                            <div class="text-caption">
                              {{ timeline.description }}
                            </div>
                          </div>
                        </div>
                        <o-action-menu
                          :actions="[
                            {
                              icon: 'mdi-pencil',
                              label: 'Modifier',
                              listeners: {
                                click: () => editTimeline(timeline.id),
                              },
                            },
                            {
                              icon: 'mdi-delete',
                              label: 'Supprimer',
                              color: 'red',
                              disabled: !isTimelineDeletable(timeline.id),
                              confirm: {
                                callback: () => deleteTimeline(timeline.id),
                                title: 'messages.views.admin.regulation.timelines.modal.deleteTimeline.title',
                                text: 'messages.views.admin.regulation.timelines.modal.deleteTimeline.text',
                                color: 'red',
                              },
                            },
                          ]"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <o-submit />
                    </v-col>
                  </v-row>
                </v-card-text>
              </o-form>
            </perfect-scrollbar>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <timeline-form-modal
      v-if="modal.timeline.show"
      v-model="modal.timeline.show"
      :timeline="modal.timeline.model"
      :traffic-light-plans="selectedCrossroad ? selectedCrossroad.trafficLightPlans : []"
      @submit="saveTimeline"
    />
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep';
  import { getCrossroadName } from '@utils/crossroad';

  export default {
    components: {
      TimelineFormModal: () => import('@/views/admin/components/regulation/timelines/TimelineFormModal'),
    },

    apollo: {
      crossroads: {
        query: require('@gql/views/admin/regulation/timelines/getCrossroads.gql'),
        client: 'stations',
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading.crossroads = res.loading;
        },
        update (data) {
          this.map.crossroads = cloneDeep(data.getCrossroads);

          if (this.map.crossroads.length) {
            if (this.selectedCrossroadId === null) {
              this.selectedCrossroadId = this.map.crossroads[0].id;
            } else {
              const selectedCrossroad = this.map.crossroads.find((crossroad) => crossroad.id === this.selectedCrossroadId);
              selectedCrossroad.selected = true;
            }
          }

          return data.getCrossroads;
        },
        error: function () {
          this.$flash('errors.apollo.query.crossroads', 'error');
        },
      },
    },

    data: () => ({
      map: {
        crossroads: [],
      },
      selectedCrossroadId: null,
      nextCrossroadId: null,
      timelineChanged: false,
      loading: {
        crossroads: true,
        timelines: false,
      },
      form: null,
      modal: {
        timeline: {
          show: false,
          model: null,
        },
      },
      newTimelineId: 1,
    }),

    computed: {
      selectedCrossroad () {
        if (!this.crossroads || this.selectedCrossroadId === null) {
          return null;
        }

        return this.crossroads.find((crossroad) => crossroad.id === this.selectedCrossroadId);
      },
      selectedCrossroadName () {
        if (!this.selectedCrossroad) {
          return null;
        }

        return getCrossroadName(this.selectedCrossroad);
      },
      isPreviousCrossroadSelectable () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);

        return index !== -1 && index > 0;
      },
      isNextCrossroadSelectable () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);

        return index !== -1 && index < this.crossroads.length - 1;
      },
    },

    watch: {
      'map.crossroads': function () {
        this.refreshCrossroadFeatures();
      },
      selectedCrossroadId: function (newValue, oldValue) {
        if (newValue !== undefined && newValue !== null) {
          const selectedCrossroad = this.map.crossroads.find((crossroad) => crossroad.id === newValue);
          selectedCrossroad.selected = true;
          this.$store.commit('map/updateFeature', {
            type: 'crossroad',
            feature: selectedCrossroad,
            refresh: false,
          });
        }
        if (oldValue !== undefined && oldValue !== null) {
          const unselectedCrossroad = this.map.crossroads.find((crossroad) => crossroad.id === oldValue);
          unselectedCrossroad.selected = false;
          this.$store.commit('map/updateFeature', {
            type: 'crossroad',
            feature: unselectedCrossroad,
            refresh: false,
          });
        }

        this.$store.commit('map/refreshMapboxFeatures', 'crossroad');
      },
      selectedCrossroad (newValue, oldValue) {
        if (newValue.location) {
          this.$store.commit('map/flyTo', {
            location: newValue.location,
          });
        }

        this.form.timelines = [];
        for (const timeline of newValue.timelines) {
          this.form.timelines.push(cloneDeep(timeline));
        }

        const timelineRules = {};
        for (const timeline of newValue.timelines) {
          for (const timelineRule of timeline.timelineRules) {
            timelineRules[timelineRule.payload.day] = cloneDeep(timelineRule);
          }
        }

        this.form.timelineRules = [];
        for (let i = 1; i <= 7; i++) {
          if (timelineRules[i] !== undefined) {
            this.form.timelineRules.push(cloneDeep(timelineRules[i]));
          } else {
            this.form.timelineRules.push({
              type: 'TYPE_DAY',
              timeline: null,
              payload: {
                day: i,
              },
            });
          }
        }
      },

      'modal.timeline.show' (newValue) {
        if (!newValue) {
          this.modal.timeline.model = null;
        }
      },
    },

    mounted () {
      this.$store.commit('map/reset');
    },

    created () {
      this.resetForm();
      this.$bus.$on('map.feature.click', this.onFeatureClick);
    },

    destroyed () {
      this.$bus.$off('map.feature.click', this.onFeatureClick);
    },

    methods: {
      refreshCrossroadFeatures () {
        if (this.map.crossroads) {
          this.$store.commit('map/setFeatures', {
            type: 'crossroad',
            features: this.map.crossroads.filter((crossroad) => crossroad.location),
          });
        }
      },

      onFeatureClick (type, id) {
        switch (type) {
          case 'crossroad':
            this.selectCrossroad(id);
            break;
        }
      },

      resetForm () {
        this.form = {
          timelineRules: [],
          timelines: [],
        };

        for (let i = 1; i <= 7; i++) {
          this.form.timelineRules.push({
            type: 'TYPE_DAY',
            timeline: null,
            payload: {
              day: i,
            },
          });
        }
      },

      selectCrossroad (id, force) {
        if (force || this.$refs.form.close()) {
          this.$refs.form.reset();
          this.selectedCrossroadId = id;
          this.nextCrossroadId = null;
          this.timelineChanged = false;
        } else {
          this.nextCrossroadId = id;
        }
      },

      previousCrossroad () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);
        if (index !== -1 && index > 0) {
          this.selectCrossroad(this.crossroads[index - 1].id);
        }
      },

      nextCrossroad () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);
        if (index !== -1 && index < this.crossroads.length - 1) {
          this.selectCrossroad(this.crossroads[index + 1].id);
        }
      },

      deleteTimeline (timelineId) {
        const index = this.form.timelines.findIndex((timeline) => timeline.id === timelineId);
        this.form.timelines.splice(index, 1);
        this.$refs.form.onFormChanged();
      },

      isTimelineDeletable (timelineId) {
        for (const timelineRule of this.form.timelineRules) {
          if (timelineRule.timeline === timelineId) {
            return false;
          }
        }

        return true;
      },

      submit () {
        this.loading.crossroads = true;

        const input = {
          crossroad: this.selectedCrossroadId,
          timelines: [],
        };

        for (const timeline of this.form.timelines) {
          const timelineRules = [];
          for (const timelineRule of this.form.timelineRules) {
            if (timelineRule.timeline === timeline.id) {
              timelineRules.push({
                type: timelineRule.type,
                payload: timelineRule.payload,
              });
            }
          }

          input.timelines.push({
            name: timeline.name,
            description: timeline.description,
            timelineRules: timelineRules,
            timelineSlots: timeline.timelineSlots,
          });
        }

        this.$apollo.mutate({
          mutation: require('@gql/mutations/timeline/updateTimelines.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: input,
          },
        }).then(() => {
          this.timelineChanged = false;
          this.$flash('messages.views.admin.components.regulation.timelines.success', 'success');
          this.$apollo.queries.crossroads.refresh();
        }).catch((e) => {
          this.$flash('messages.views.admin.components.regulation.timelines.error', 'error');
        }).finally(() => {
          this.loading.crossroads = false;
        });
      },

      createTimeline () {
        this.modal.timeline.model = null;
        this.modal.timeline.show = true;
      },

      editTimeline (timelineId) {
        const timeline = this.form.timelines.find((timeline) => timeline.id === timelineId);

        this.modal.timeline.model = timeline;
        this.modal.timeline.show = true;
      },

      saveTimeline (data) {
        this.modal.timeline.show = false;

        let timeline = this.modal.timeline.model;
        if (timeline === null) {
          timeline = {
            id: this.generateNewTimelineId(),
          };
          this.form.timelines.push(timeline);
        }

        timeline.name = data.name;
        timeline.description = data.description;
        timeline.timelineSlots = data.timelineSlots;

        this.timelineChanged = true;

        this.$refs.form.onFormChanged();
      },

      generateNewTimelineId () {
        return '_' + (++this.newTimelineId);
      },
    },
  };
</script>

<style scoped lang="sass">
</style>
